<script setup lang="ts">
    import type { BadgeType } from '~/@types/cms';

    interface BadgeIntroProps {
        badge?: BadgeType;
    }
    const props = defineProps<BadgeIntroProps>();

    const { t } = useLocales();

    const description = computed(() => {
        if (!props.badge) return;
        return t(`badges.${props.badge}`);
    });
</script>
<template>
    <div class="atm-badge-intro flex items-center gap-3 max-lg:mx-8 max-md:mx-6 lg:max-w-lg">
        <component
            :is="badgeIcon(badge)"
            class="h-14 w-14 shrink-0" />
        <div v-if="description">
            <renderer-html :content="woomTextFormat(description)" />
        </div>
    </div>
</template>
